/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Poppins, "Helvetica Neue", sans-serif; }

.base-container {
    width: 100%;
}

.container--height {
    height: 100%;
}

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.overflow--unset {
    overflow: unset !important;
}
